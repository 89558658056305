import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useTable, useSortBy, usePagination } from 'react-table';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import './App.css';

function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [search, setSearch] = useState('');
  const [roleId, setRoleId] = useState('');
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchUsers = useCallback(async (page = 0) => {
    const token = localStorage.getItem('token');
    const queryParams = new URLSearchParams({
      search,
      roleId,
      dateStart,
      dateEnd,
      page
    }).toString();

    try {
      const response = await axios.get(`https://tanayoupop.com.br/staff/api/getUsers.php?${queryParams}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      // Ordena os usuários pelo ID de forma decrescente antes de atualizar o estado
      const sortedUsers = response.data.sort((a, b) => b.id - a.id);
      if (page > 0) {
        setUsers(prevUsers => [...prevUsers, ...sortedUsers]);
      } else {
        setUsers(sortedUsers);
      }
      setCurrentPage(page);
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
    }
  }, [search, roleId, dateStart, dateEnd]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setLoggedIn(true);
      fetchUsers();
    }
  }, [fetchUsers]);

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('https://tanayoupop.com.br/staff/api/login.php', { username, password });
      if (response.data.success) {
        localStorage.setItem('token', response.data.token);
        setLoggedIn(true);
        fetchUsers();
      } else {
        alert('Falha no login: ' + response.data.message);
      }
    } catch (error) {
      console.error('Erro de login:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setLoggedIn(false);
    setUsers([]);
    setCurrentPage(0);
  };

  const loadMoreUsers = () => {
    fetchUsers(currentPage + 1);
  };

  const resetFilters = () => {
    setSearch('');
    setRoleId('');
    setDateStart('');
    setDateEnd('');
    fetchUsers();
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(users);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    XLSX.writeFile(wb, "users.xlsx");
  };

  return (
    <Router>
      <div className={`app-container ${isLoggedIn ? 'bg-logged' : ''}`}>
        {isLoggedIn && (
          <header className="app-header flex justify-between items-center p-4 text-white">
            <img className="logoapp" src="https://app.tanayoupop.com.br/assets/img/logos/logo.png?v=1677859874mFhur" alt="Logo do aplicativo" />
            <h1>Painel administrativo</h1>
            <nav>
              <ul className="flex gap-4">
                <li><a href="/">Início</a></li>
                <li><a href="/account">Minha Conta</a></li>
                <li><button onClick={handleLogout} className="btn btn-error">Deslogar</button></li>
              </ul>
            </nav>
          </header>
        )}
        <Routes>
          <Route path="/login" element={!isLoggedIn ? <LoginForm onLogin={handleLogin} setUsername={setUsername} setPassword={setPassword} loading={loading} /> : <Navigate to="/" replace />} />
          <Route path="/" element={isLoggedIn ? <UserDashboard users={users} onLogout={handleLogout} search={search} setSearch={setSearch} roleId={roleId} setRoleId={setRoleId} dateStart={dateStart} setDateStart={setDateStart} dateEnd={dateEnd} setDateEnd={setDateEnd} loadMoreUsers={loadMoreUsers} resetFilters={resetFilters} exportToExcel={exportToExcel} /> : <Navigate to="/login" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
  };
  // Usando 'pt-BR' para garantir o formato de data em português
  return date.toLocaleDateString('pt-BR', options).replace(',', ' às');
}

function LoginForm({ onLogin, setUsername, setPassword, loading }) {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-lg">
      <center><img className="logoapp text-lg text-center" src="https://app.tanayoupop.com.br/assets/img/logos/logo.png?v=1677859874mFhur" alt="Logo do aplicativo" /> </center>
            <h1 className="text-lg text-center">Painel administrativo</h1>        <form onSubmit={onLogin} className="space-y-6">
          <div>
            <label className="block text-gray-700">Usuário</label>
            <input type="text" onChange={(e) => setUsername(e.target.value)} required className="w-full p-2 border rounded" />
          </div>
          <div>
            <label className="block text-gray-700">Senha</label>
            <input type="password" onChange={(e) => setPassword(e.target.value)} required className="w-full p-2 border rounded" />
          </div>
          <button type="submit" className="w-full p-2 text-white bg-blue-500 rounded hover:bg-blue-700" disabled={loading}>
            {loading ? 'Entrando...' : 'Entrar'}
          </button>
        </form>
      </div>
    </div>
  );
}

function UserDashboard({ users, onLogout, search, setSearch, roleId, setRoleId, dateStart, setDateStart, dateEnd, setDateEnd, resetFilters, exportToExcel }) {
  const data = React.useMemo(() => 
    users.map(user => ({
      ...user,
      created_at: formatDate(user.created_at),
      nivel: user.nivel === 'Indefinido' ? 'Cliente' : user.nivel // Converte "Indefinido" em "Cliente"
    })),
    [users] // Dependência que garante que o useMemo será re-executado sempre que 'users' mudar
  );

  const {
    getTableProps, getTableBodyProps, headerGroups, page, prepareRow,
    canPreviousPage, canNextPage, nextPage, previousPage,
    pageCount, gotoPage, state: { pageIndex }
  } = useTable({
    columns: React.useMemo(() => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Nome', accessor: 'name' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Telefone/cel', accessor: 'phone' },
      { Header: 'Nível', accessor: 'nivel' },
      { Header: 'Cadastrado em', accessor: 'created_at' }
    ], []),
    data, // Usando os dados processados
    initialState: { pageIndex: 0, pageSize: 100 }
  }, useSortBy, usePagination);

  return (
    <div className="user-dashboard container mx-auto p-4" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
      <div className="filters flex justify-between items-center mb-4">
        <input type="text" value={search} onChange={e => setSearch(e.target.value)} placeholder="Buscar por nome, email ou telefone" className="input input-bordered" />
        <select onChange={e => setRoleId(e.target.value)} className="input input-bordered">
          <option value="">Todos</option>
          <option value="3">Lojista</option>
          <option value="2">Motoboy</option>
          <option value="4">Cliente</option>
        </select>
        <input type="date" value={dateStart} onChange={e => setDateStart(e.target.value)} className="input input-bordered" />
        <input type="date" value={dateEnd} onChange={e => setDateEnd(e.target.value)} className="input input-bordered" />
        <button onClick={resetFilters} className="btn btn-secondary">Resetar Filtros</button>
        <button onClick={exportToExcel} className="btn btn-green-500">Exportar para Excel</button>
      </div>
      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {column.render('Header')}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination flex justify-between items-center mt-4">
        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l">
          Anterior
        </button>
        <div>
          {Array.from({ length: pageCount }, (_, i) => (
            <button
              key={i}
              className={`btn ${i === pageIndex ? 'btn-active bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l' : 'btn-outline bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l'}`}
              onClick={() => gotoPage(i)}
            >
              {i + 1}
            </button>
          ))}
        </div>
        <button onClick={() => nextPage()} disabled={!canNextPage} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l">
          Próximo
        </button>
      </div>
    </div>
  );
}

export default App;
